import { useState } from 'react';
import { isChrome } from 'react-device-detect';
import exitImg from 'assets/pngs/exit.png';
import { Flex } from 'theme-ui';
import { exit, toast } from './styles';

const UnsupportedBrowsersToast = (): JSX.Element | null => {
  const [showToast, setShowToast] = useState(!isChrome);

  if (!showToast) return null;

  const msg =
    'Hello, we see that you are using a browser other than Chrome. Chrome is the only officially supported browser so if anything is not working as expected please try the tool in Chrome';

  const chromeLink = 'https://www.google.com/chrome/';
  return (
    <Flex sx={toast}>
      <Flex>
        <span>
          {msg}
          <a sx={{ marginLeft: '6px' }} href={chromeLink}>
            download
          </a>
        </span>
      </Flex>
      <img
        src={exitImg}
        alt="exit"
        sx={exit}
        onClick={(): void => setShowToast(false)}
      />
    </Flex>
  );
};

export default UnsupportedBrowsersToast;
