import SuccessSvg from 'assets/svgs/success.svg';
import exitImg from 'assets/pngs/exit.png';
import {
  toastCard,
  body,
  colLeft,
  header,
  msg,
  exit,
  innerCard,
  linkStyle,
  underlineStyle,
} from './styles';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { notificationSelector } from 'src/state/selectors/notification';
import {
  hideNotification,
  NotificationType,
} from 'src/state/slices/notification';
import Fade from '../Animations/Fade';
import { useEffect, useState } from 'react';
import { IconUndelivered } from '@odekoteam/doppio';
import { ON_CALL_URL } from 'src/utils';
import { setRouteDetailsCardVisible } from 'src/state/slices/delivery';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deliveryPath } from '../Navbar';

const FADE_OUT_SECONDS = 4;
const DEFAULT_SUCCESS_MESSAGE = 'Completed successfully';
const DEFAULT_ERROR_MESSAGE =
  'An error has occurred, please file an on call request ';
const ERROR_CODE_LABEL = 'Error code: ';

const ToastNotification = (): JSX.Element | null => {
  const { isShowing, type, message, link, errorCode, route } =
    useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [seconds, setSeconds] = useState<number>(0);
  const [hover, setHover] = useState<boolean>(false);
  const [display, setDisplay] = useState('none');
  const displayErrorCode = errorCode ? errorCode : 'Unknown error';

  let displayMessage = message;

  if (!message) {
    displayMessage =
      type === NotificationType.Success
        ? DEFAULT_SUCCESS_MESSAGE
        : DEFAULT_ERROR_MESSAGE;
  }

  let displayLink = link;
  if (type === NotificationType.Error && !link) {
    displayLink = {
      url: ON_CALL_URL,
      label: 'submit on call',
    };
  }

  useEffect(() => {
    let interval: any = null;
    if (isShowing) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isShowing && seconds !== 0) {
      setSeconds(0);
      clearInterval(interval);
    }

    if (seconds >= FADE_OUT_SECONDS && !hover) {
      dispatch(hideNotification());
    }

    return () => clearInterval(interval);
  }, [isShowing, seconds, hover, dispatch]);

  // sets the notification to 'none' when the notification is not visible
  // so that user can click in that area when the notification is not visible
  useEffect(() => {
    if (isShowing) setDisplay('block');
    const timer = setTimeout(() => {
      if (!isShowing) setDisplay('none');
    }, 1000);
    return () => clearTimeout(timer);
  }, [isShowing]);

  if (isShowing === undefined) return null;

  return (
    <Fade style={{ ...toastCard, display }} toggleAnimation={isShowing}>
      <div
        id="toastNotification"
        className={type}
        sx={innerCard}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
      >
        <div sx={colLeft}>
          <div sx={{ width: '28px' }}>
            {type === NotificationType.Success ? (
              <SuccessSvg />
            ) : (
              <IconUndelivered height={28} width={28} color="errorIcon" />
            )}
          </div>
          <div sx={body}>
            <span sx={header}>
              {type === NotificationType.Success ? 'Success' : 'Error'}
            </span>
            <div sx={msg}>
              <span>{displayMessage}</span>
              {route && (
                <span
                  sx={underlineStyle}
                  onClick={(): void => {
                    if (route.id) {
                      dispatch(setRouteDetailsCardVisible(route.id));
                      navigate(`${deliveryPath}?${searchParams.toString()}`);
                    }
                  }}
                >
                  {route.name}
                </span>
              )}
              {displayLink && (
                <a
                  href={displayLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={linkStyle}
                >
                  {displayLink.label}
                </a>
              )}
            </div>
            {type === NotificationType.Error && (
              <div sx={msg}>
                {ERROR_CODE_LABEL} {displayErrorCode}
              </div>
            )}
          </div>
        </div>
        <img
          src={exitImg}
          alt="exit"
          sx={exit}
          onClick={(): void => dispatch(hideNotification())}
        />
      </div>
    </Fade>
  );
};

export default ToastNotification;
