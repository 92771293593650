import { ThemeUICSSObject } from 'theme-ui';
import SvgIconRefrigerated from 'assets/icons/SvgIconRefrigerated';
import BaseIcon from './BaseIcon';

const RefrigerationRequiredIcon = ({
  color,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  color?: string;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`refrigeration-icon-${testId}`}
      labelText="Requires refrigeration"
    >
      <SvgIconRefrigerated color={color} />
    </BaseIcon>
  );
};

export default RefrigerationRequiredIcon;
