import { ThemeUICSSObject } from 'theme-ui';
import SvgIconFrozen from 'assets/icons/SvgIconFrozen';
import BaseIcon from './BaseIcon';

const FreezerRequiredIcon = ({
  color,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  color?: string;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`frozen-icon-${testId}`}
      labelText="Requires freezer"
    >
      <SvgIconFrozen color={color} />
    </BaseIcon>
  );
};

export default FreezerRequiredIcon;
