import ArrowIcon from 'assets/pngs/arrow.png';
import Rotate from './Animations/Rotate';

const DEFAULT_START_DEGREES = -180;
const DEFAULT_END_DEGREES = 0;

const RotatingArrow = ({
  isRotated,
  startDegrees = DEFAULT_START_DEGREES,
  endDegrees = DEFAULT_END_DEGREES,
  id,
}: {
  isRotated?: boolean;
  startDegrees?: number;
  endDegrees?: number;
  id?: string;
}): JSX.Element => {
  return (
    <Rotate
      id={id}
      startDegrees={startDegrees}
      endDegrees={endDegrees}
      toggleAnimation={isRotated}
    >
      <img src={ArrowIcon} alt="arrow" sx={{ width: '12px', height: 'auto' }} />
    </Rotate>
  );
};

export default RotatingArrow;
