import * as React from 'react';

const SvgIconFrozen = ({
  color = '#615E54',
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={22} {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.002.5h-.053C7.23.5 5.866.5 4.793.636 3.691.775 2.79 1.068 2.06 1.732a5 5 0 0 0-.327.327C1.068 2.79.775 3.69.636 4.793.527 5.651.506 6.695.5 7.96v.077L.5 8.949v4.104c0 1.72 0 3.084.136 4.156.139 1.103.432 2.003 1.096 2.735q.154.171.327.327c.731.663 1.632.956 2.734 1.096 1.073.136 2.436.136 4.156.136h.106c1.72 0 3.083 0 4.156-.136 1.102-.14 2.002-.433 2.734-1.096q.171-.156.327-.327c.664-.732.957-1.632 1.096-2.735.136-1.072.136-2.435.136-4.156V8.945l-.001-.91a1 1 0 0 0 0-.076c-.005-1.264-.026-2.308-.135-3.166-.14-1.102-.432-2.003-1.096-2.734a5 5 0 0 0-.327-.327c-.732-.664-1.632-.957-2.734-1.096C12.138.5 10.775.5 9.055.5zm6.996 6.75c-.011-.915-.04-1.653-.118-2.269-.12-.948-.345-1.503-.719-1.914a3 3 0 0 0-.224-.224c-.412-.374-.966-.6-1.914-.72C12.055 2.002 10.787 2 9.002 2s-3.053.001-4.02.124c-.95.12-1.504.345-1.915.719a3 3 0 0 0-.224.224c-.374.411-.6.966-.72 1.914-.077.616-.106 1.354-.117 2.269zM2 8.75v4.251c0 1.785.001 3.053.124 4.02.12.949.345 1.503.719 1.915q.105.117.224.224c.411.373.966.599 1.914.719.968.122 2.236.123 4.02.123 1.786 0 3.054 0 4.022-.123.948-.12 1.502-.346 1.914-.72q.117-.105.224-.223c.374-.412.6-.966.719-1.915.122-.967.124-2.235.124-4.02V8.75zm5.457 1.47a.75.75 0 0 1 1.06 0l.482.482.481-.482a.75.75 0 0 1 1.062 1.06l-.793.794v1.125l.973-.562.29-1.084a.75.75 0 1 1 1.448.388l-.177.66.66.176a.75.75 0 0 1-.39 1.45l-1.082-.29-.973.562.973.563 1.082-.29a.75.75 0 0 1 .39 1.449l-.66.176.177.66a.75.75 0 1 1-1.448.388l-.29-1.084-.973-.562v1.125l.793.794a.75.75 0 0 1-1.062 1.06L9 18.296l-.481.482a.75.75 0 1 1-1.062-1.06l.793-.794V15.8l-.973.562-.29 1.084a.75.75 0 1 1-1.448-.388l.176-.66-.659-.176a.75.75 0 0 1 .39-1.45l1.082.29.973-.562-.973-.563-1.083.29a.75.75 0 0 1-.388-1.449l.658-.177-.176-.66a.75.75 0 1 1 1.448-.387l.29 1.084.973.562v-1.125l-.793-.794a.75.75 0 0 1 0-1.06M13.5 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconFrozen;
