import * as React from 'react';

const SvgIconRefrigerated = ({
  color = '#615E54',
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={22} {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.002.5h-.053C7.23.5 5.866.5 4.793.636 3.691.775 2.79 1.068 2.06 1.732a5 5 0 0 0-.327.327C1.068 2.79.775 3.69.636 4.793.5 5.866.5 7.23.5 8.95v4.104c0 1.72 0 3.084.136 4.156.139 1.103.432 2.003 1.096 2.735q.154.171.327.327c.731.663 1.632.956 2.734 1.096 1.073.136 2.436.136 4.156.136h.106c1.72 0 3.083 0 4.156-.136 1.102-.14 2.002-.433 2.734-1.096q.171-.156.327-.327c.664-.732.957-1.632 1.096-2.735.136-1.072.136-2.435.136-4.156V8.95c0-1.72 0-3.083-.136-4.156-.14-1.102-.432-2.003-1.096-2.734a5 5 0 0 0-.327-.327c-.732-.664-1.632-.957-2.734-1.096C12.138.5 10.775.5 9.055.5zm7.002 8.776v-.274c0-1.785-.002-3.053-.124-4.02-.12-.95-.345-1.504-.719-1.915a3 3 0 0 0-.224-.224c-.412-.374-.966-.6-1.914-.72C12.055 2.002 10.787 2 9.002 2s-3.053.001-4.02.124c-.95.12-1.504.345-1.915.719a3 3 0 0 0-.224.224c-.374.411-.6.966-.72 1.914C2.002 5.95 2 7.217 2 9.001v.275zm0 1.5v2.225c0 1.785-.002 3.053-.124 4.02-.12.949-.345 1.503-.719 1.915a3 3 0 0 1-.224.224c-.412.373-.966.599-1.914.719-.968.122-2.236.123-4.021.123s-3.053 0-4.02-.123c-.95-.12-1.504-.346-1.915-.72a3 3 0 0 1-.224-.223c-.374-.412-.6-.966-.72-1.915-.122-.967-.123-2.235-.123-4.02v-2.225zM5 3.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V4A.75.75 0 0 1 5 3.25M5.75 13a.75.75 0 0 0-1.5 0v5a.75.75 0 0 0 1.5 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconRefrigerated;
