import { keyframes } from '@emotion/react';
import loader from 'assets/pngs/loader.png';
import { ThemeUICSSObject } from 'theme-ui';

export const opacityOverlay: ThemeUICSSObject = {
  height: '100%',
  width: '100%',
  backdropFilter: 'blur(1px)',
  position: 'absolute',
  zIndex: 25,
};
export const opacityContainer: ThemeUICSSObject = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 25,
};

export const LoaderSpinner = ({
  imgStyle,
}: {
  imgStyle?: ThemeUICSSObject;
}): JSX.Element => {
  const keyFrameForward = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;
  const animation: ThemeUICSSObject = {
    zIndex: 50,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: `${keyFrameForward} 2s infinite linear`,
  };
  return <img src={loader} alt="loader" sx={{ ...animation, ...imgStyle }} />;
};

const Loader = ({
  containerStyle,
  imgStyle,
}: {
  containerStyle?: ThemeUICSSObject;
  imgStyle?: ThemeUICSSObject;
}): JSX.Element => {
  return (
    <div sx={{ ...opacityContainer, ...containerStyle }}>
      <LoaderSpinner imgStyle={imgStyle} />
      <div sx={opacityOverlay} />
    </div>
  );
};

export default Loader;
