import * as React from 'react';

const SvgIconSun = ({
  color = '#615E54',
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g stroke={color} strokeWidth={1.5}>
      <circle cx={12} cy={12} r={5} />
      <path
        strokeLinecap="round"
        d="M12 2v2M12 20v2M4 12H2M22 12h-2M19.778 4.223l-2.222 2.031M4.222 4.223l2.222 2.031M6.444 17.556l-2.222 2.222M19.778 19.777l-2.222-2.222"
      />
    </g>
  </svg>
);
export default SvgIconSun;
