import { GLocationMembershipTier } from 'src/graphql/types/generated';
import SilverBadge from 'assets/svgs/silverBadge.svg';
import GoldBadge from 'assets/svgs/goldBadge.svg';
import { Flex, ThemeUICSSObject } from 'theme-ui';
import { Tooltip } from '@odekoteam/doppio';

const badgeBackground: ThemeUICSSObject = {
  padding: '4px',
  borderRadius: '4px',
  alignItems: 'center',
};

const countStyle: ThemeUICSSObject = {
  marginLeft: '2px',
};

type BadgeConfig = {
  background: string;
  tooltipText: string;
  badgeIcon: JSX.Element;
};

const getBadgeConfig = (
  membershipTier: GLocationMembershipTier,
): BadgeConfig | null => {
  switch (membershipTier) {
    case GLocationMembershipTier.Gold:
      return {
        background: 'linear-gradient(152deg, #FFD100 0%, #FFF2B6 100%)',
        tooltipText: 'Gold Member',
        badgeIcon: <GoldBadge />,
      };
    case GLocationMembershipTier.Silver:
      return {
        background: 'linear-gradient(152deg, #EEEBE6 0%, #EDEDED 100%)',
        tooltipText: 'Silver Member',
        badgeIcon: <SilverBadge />,
      };
    default:
      return null;
  }
};

const MembershipBadge = ({
  membershipTier,
  count,
  style = {},
  testId,
}: {
  membershipTier: GLocationMembershipTier;
  count?: number;
  style?: ThemeUICSSObject;
  testId?: string;
}): JSX.Element | null => {
  if (count === 0) return null;

  const badgeConfig = getBadgeConfig(membershipTier);
  if (!badgeConfig) return null;

  const { background, tooltipText, badgeIcon } = badgeConfig;

  return (
    <Tooltip labelText={tooltipText} placement="bottom">
      <Flex
        data-test-id={`${membershipTier.toLowerCase()}-badge-${testId}`}
        sx={{
          ...(count ? badgeBackground : {}),
          ...(count ? { background } : {}),
          ...style,
        }}
      >
        {badgeIcon}
        {count && <span sx={countStyle}>{count}</span>}
      </Flex>
    </Tooltip>
  );
};

export default MembershipBadge;
