import { ThemeUICSSObject } from 'theme-ui';
import SvgIconMoon from 'assets/icons/SvgIconMoon';
import BaseIcon from './BaseIcon';

const NighttimeIcon = ({
  color,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  color?: string;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`nighttime-icon-${testId}`}
      labelText="Nighttime delivery window"
    >
      <SvgIconMoon color={color} />
    </BaseIcon>
  );
};

export default NighttimeIcon;
