import { ThemeUICSSObject } from 'theme-ui';
import SvgIconSun from 'assets/icons/SvgIconSun';
import BaseIcon from './BaseIcon';

const DaytimeIcon = ({
  color,
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  color?: string;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`daytime-icon-${testId}`}
      labelText="Daytime delivery window"
    >
      <SvgIconSun color={color} />
    </BaseIcon>
  );
};

export default DaytimeIcon;
