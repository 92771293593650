import { ThemeUICSSObject } from 'theme-ui';
import DoorDashSvg from 'assets/svgs/doorDash.svg';
import BaseIcon from './BaseIcon';

const DoorDashIcon = ({
  testId,
  ...rest
}: {
  count?: number;
  style?: ThemeUICSSObject;
  testId?: string;
}): JSX.Element | null => {
  return (
    <BaseIcon
      {...rest}
      testId={`doordash-icon-${testId}`}
      labelText="DoorDash customer"
    >
      <DoorDashSvg />
    </BaseIcon>
  );
};

export default DoorDashIcon;
